<template>
	<div :class="props.centered ? 'text-center' : ''">
		<div class="h-24 flex items-center justify-center px-6 mb-2">
			<img
				v-if="props.merchant.logoUrl"
				:src="`https://tengiva.imgix.net/${props.merchant.logoUrl}`"
				class="max-h-full"
			/>
			<div
				v-else
				class="flex items-center justify-center w-full"
			>
				<p class="tg-title-font text-2xl font-bold text-center">
					{{ props.merchant.name }}
				</p>
			</div>
		</div>

		<p
			:class="`tg-title-font text-xl font-bold ${!props.merchant.logoUrl ? 'invisible' : ''} ${
				props.centered ? 'text-center' : ''
			}`"
		>
			{{ props.merchant.name }}
		</p>
		<div :class="`flex items-center gap-1 ${props.centered ? 'justify-center' : ''}`">
			<span
				class="*:h-4 *:w-4"
				v-html="props.merchant.countryFlag"
			></span>
			<span class="ml-1">{{ props.merchant.country }}</span>
		</div>
		<p>
			{{ numOfProducts }} {{ $t("components.merchant_card.products", { count: props.merchant.numberOfProducts || 0 }) }}
		</p>
		<p
			v-if="warehouses"
			class="text-neutral-700 h-[50px] overflow-hidden"
		>
			{{ $t("components.merchant_card.ships_from") }} {{ warehouses }}
		</p>
		<p
			v-else
			class="h-[50px]"
		></p>
		<NuxtLink :to="'/merchants/' + props.merchant.id">
			<Button
				class="mt-2 tg-btn"
				color="primary"
				:height="48"
				block
			>
				{{ $t("components.merchant_card.view_merchant_btn") }}
			</Button>
		</NuxtLink>
	</div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import type { MerchantCardInfo } from "@/types/merchant";

import { formatNumberWithCommas } from "@/utils/helpers";
import Button from "@/components/Button.vue";

const props = defineProps<{ merchant: MerchantCardInfo; centered: boolean }>();

const numOfProducts = computed(() => {
	// missing-translation (localization for number formatting)
	if (props.merchant.numberOfProducts !== null) {
		return formatNumberWithCommas(props.merchant.numberOfProducts);
	} else {
		return 0;
	}
});

const warehouses = computed(() => {
	if (!props.merchant.warehouses) return;
	const hidden = props.merchant.warehouses.length > 2 ? props.merchant.warehouses.length - 2 : 0;
	const sorted = [...props.merchant.warehouses].sort((w1, w2) => {
		return w1.main === w2.main ? 0 : w1.main ? -1 : 1;
	});

	let warehouses_text = sorted
		.slice(0, 2)
		.map(w => `${w.name} (${w.country})`)
		.join(", ");
	if (hidden) {
		warehouses_text += ` + ${hidden} more`;
	}
	return warehouses_text;
});
</script>
