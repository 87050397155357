import { defineStore } from "pinia";
import type { ActiveMerchant, MerchantCardInfo } from "@/types/merchant";
export const useMerchantsStore = defineStore(
	"merchants",
	() => {
		const { listActiveMerchantsMarketplace } = useECMApi();
		const { $countries } = useNuxtApp();

		const merchantsFromApi = ref<ActiveMerchant[]>([]);
		const merchants = computed<MerchantCardInfo[]>(() => {
			return merchantsFromApi.value.map(m => {
				const c = getCountry(m.address?.country);
				const warehouses = m.warehouses?.map(w => {
					return { name: w.warehouse_name, country: w.address.country, main: w.main_warehouse };
				});

				return {
					id: m.merchant_data.id,
					logoUrl: m.merchant_data.branding?.logo_img_id,
					name: m.merchant_data.profile.public_name,
					numberOfProducts: m.no_of_products,
					joined: m.joined_date,
					country: c.name,
					countryFlag: c.flag,
					warehouses: warehouses?.map(warehouse => {
						return { ...warehouse, country: getCountry(warehouse.country).name };
					}),
				};
			});
		});

		function getCountry(code: string) {
			const countryObj = $countries?.list.value.find(c => c.value === code);
			return !countryObj ? { name: code, flag: "" } : { name: countryObj.title, flag: countryObj.flagSvg };
		}

		async function getMerchants(orgId: string) {
			const resActiveMerchants = await listActiveMerchantsMarketplace(orgId);
			merchantsFromApi.value = resActiveMerchants?.response?.data || [];
		}

		async function getRecentlyListedMerchants(orgId: string, n = 6): Promise<MerchantCardInfo[]> {
			if (!merchants.value.length) await getMerchants(orgId);

			return merchants.value
				.sort((m1, m2) => {
					const ts1 = m1.joined ? new Date(m1.joined).getTime() : 0;
					const ts2 = m2.joined ? new Date(m2.joined).getTime() : 0;
					return ts2 - ts1;
				})
				.slice(0, n);
		}

		return {
			merchants,
			getMerchants,
			getRecentlyListedMerchants,
		};
	}
	// {
	// 	persist: {
	// 		storage: persistedState.sessionStorage,
	// 	},
	// }
);
